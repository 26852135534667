<template>
    <div class="Createaproject">
        <!-- 导航栏 -->
        <div class="project-nav">
            <!-- 左侧 -->
            <div class="project-name-box">
                <div class="back-button">
                    <el-tooltip class="item" effect="dark" content="返回" placement="bottom">
                        <button class="mu-icon-button" @click="back()">
                            <div class="">
                                <div class="mu-ripple-wrappers"></div> 
                                <i class="mu-icon material-icons el-icon-arrow-left"></i>
                            </div>
                        </button>
                    </el-tooltip>
                </div>
                <div class="project-menu-wrapper-box" style="pointer-events: auto;" @click="navBox()" v-close>
                    <div class="project-name has-tooltip">
                        {{pro_names}}
                    </div>
                    <span class="arrow-drop-down"></span>
                    <div class="mu-popover" :aria-hidden="show" v-show="show">
                        <div class="mu-menu project-menu-top-list" style="min-height: 96px; width: 224px;">
                            <div class="mu-ripple-wrapper" :class="item.name==pro_names?'active':''" v-for="(item,index) in proList" :key="index" @click="choDiv(item)">
                                {{item.name}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 右侧 -->
            <div class="project-menu">
                <div class="menu_one">
                    <el-tooltip class="item" effect="dark" content="资料库" placement="bottom">
                        <i class="iconfont icon-folder" @click="drawers()"></i>
                    </el-tooltip>
                </div>
                <!-- <div class="menu_one">
                    <el-tooltip class="item" effect="dark" content="项目通知" placement="bottom">
                        <el-popover
                            placement="bottom-end"
                            width="400"
                            trigger="click"
                            popper-class="popoverDiv"
                        >
                            <div>123</div>
                            <i class="iconfont icon-tongzhi"  slot="reference"></i>
                        </el-popover>
                    </el-tooltip>
                </div> -->
                <div class="t-menu-div" @mouseover="mouseover()" @mouseout="mouseout()">
                    <div class="p_img">
                        <img :src="this.$store.state.user_avatar===null ||this.$store.state.user_avatar==''||this.$store.state.user_avatar=='null'?'https://static.ibeisai.com/image/default_avatar.png':this.$store.state.user_avatar" alt="">
                    </div>
                    <p class="names">{{this.$store.state.user_name===null||this.$store.state.user_name===''||this.$store.state.user_name==='null'?'':this.$store.state.user_name}}</p>
                    <ul class="dropdown-menus" v-show="login_acc==true">
                        <li @click="count()">账号设置</li>
                        <li @click="exit()">安全退出</li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 内容 -->
        <div class="project-center clearfix">
            <!-- 左侧导航 -->
            <div class="p_center_left fl">
                <el-tree
                    :data="treedata"
                    accordion
                    ref="trees"
                    show-checkbox
                    node-key="id"
                    :props="defaultProps"
                    :check-strictly="systemNodeFlag" 
                    :default-expanded-keys="choiceId"
                    @check="checkNode">
                </el-tree>
            </div>
            <div class="p_center_right fl">
                <div class="right_top clearfix">
                    <div class="fl">
                        <el-button type="primary" @click="Newadd()">项目基本信息</el-button>
                    </div>
                    <div class="fr">
                        <el-button type="primary" @click="mapBotn()">项目立意雷达图</el-button>
                        <el-button type="primary" @click=" printBox()">打印BP框架</el-button>
                        <el-button type="info">生成BP文档</el-button>
                    </div>
                </div>
                <div class="right_content">
                    <el-row :gutter="20">
                        <el-col 
                            :xs="24" 
                            :md="12" 
                            :lg="8" 
                            :xl="6"
                            v-for="(item,index) in typeList" :key="index"
                        >
                            <div class="rivon_box">
                                <!-- <el-tooltip class="item" effect="dark" content="教师批注" placement="bottom"> -->
                                    <div class="rivon_box_div" v-show="item.remark!==''&&item.remark!==null">
                                        <el-popover
                                            placement="bottom"
                                            title="教师批注"
                                            width="300"
                                            trigger="hover"
                                            :content="item.remark">
                                            <i class="el-icon-info" slot="reference"></i>
                                        </el-popover>
                                    </div>
                                    
                                <!-- </el-tooltip> -->
                                <div class="rivon_info">
                                    <div class="rivon_container">
                                        <h5 class="text-overflow m-t-0" style="margin-bottom:0">{{item.name}}</h5>
                                    </div>
                                    <div class="must_info" style="margin-top:0">
                                        <p class="must_muted" v-for="(citem,cindex) in item.fmList" :key="cindex">
                                            <span class="fm-title">{{citem.name}}：</span>{{citem.value}}
                                        </p>
                                    </div>
                                    <div class="must_data clearfix">
                                        <span class="fl span_data">
                                            更新：{{item.updateUserName}} &nbsp;&nbsp;{{item.updateTime}}
                                        </span>
                                        <span class="fr">
                                            <i class="el-icon-edit-outline" @click="updateBtn(item)"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
        <!-- footer -->
        <footer class="footer text-right">
            <span>© 2021. 创项课服务平台_v1.2.0  All rights reserved.</span>
        </footer>
        <!-- 项目信息弹窗 -->
        <div class="joinclass_pop">
            <el-dialog
                :visible.sync="dialogVisible"
                :show-close="false"
                width="60%"
                :before-close="handleClose">
                    <!-- <i class="el-icon-close closei" @click="closei('ruleForm')"></i> -->
                    <ul class="tab_box clearfix">
                        <li class="fl" :class="active==0?'active':''" @click="istrues(0)">
                            <span>基础信息</span>
                        </li>
                        <li class="fl" :class="active==1?'active':''" @click="istrues(1)">
                            <span>其它信息</span>
                        </li>
                    </ul>
                    <el-row :gutter="40">
                        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" v-show="active==0">
                            <el-col 
                                :xs="24" 
                                :md="12"
                            >
                                <el-form-item label="项目名称：" prop="name">
                                    <el-input v-model="ruleForm.name" maxlength="30" show-word-limit placeholder="不超过30个字符"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col 
                                :xs="24" 
                                :md="12"
                            >
                                <el-form-item label="项目类型：" prop="type">
                                    <el-radio-group v-model="ruleForm.type" disabled>
                                        <el-radio label="创意">创意</el-radio>
                                        <el-radio label="初创/成长">初创/成长</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                            <el-col 
                                :xs="24" 
                                :md="12"
                            >
                                <el-form-item label="需求：" prop="lyXq">
                                    <el-input v-model.number="ruleForm.lyXq" maxlength="30" show-word-limit placeholder="不超过30个字符"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col 
                                :xs="24" 
                                :md="12"
                            >
                                <el-form-item label="目标：" prop="lyMb">
                                    <el-input v-model.number="ruleForm.lyMb" maxlength="30" show-word-limit placeholder="不超过30个字符"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col 
                                :xs="24" 
                                :md="12"
                            >
                                <el-form-item label="创新：" prop="lyCx">
                                    <el-input v-model.number="ruleForm.lyCx" maxlength="30" show-word-limit placeholder="不超过30个字符"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col 
                                :xs="24" 
                                :md="12"
                            >
                                <el-form-item label="适配：" prop="lySp">
                                    <el-input v-model.number="ruleForm.lySp" maxlength="30" show-word-limit placeholder="不超过30个字符"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col 
                                :xs="24" 
                                :md="12"
                            >
                                <el-form-item label="可行：" prop="lyKx">
                                    <el-input v-model.number="ruleForm.lyKx" maxlength="30" show-word-limit placeholder="不超过30个字符"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col 
                                :xs="24" 
                                :md="12"
                            >
                                <el-form-item label="效益：" prop="lyXy">
                                    <el-input v-model.number="ruleForm.lyXy" maxlength="30" show-word-limit placeholder="不超过30个字符"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form>
                        <el-form ref="Forms" :model="Forms" :rules="rules" v-show="active==1">
                            <el-col 
                                :xs="24" 
                                :md="12"
                            >
                                <el-form-item label="目标市场洞察：" prop="sjMbsc">
                                    <el-input v-model="Forms.sjMbsc" maxlength="30" show-word-limit placeholder="不超过30个字符"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col 
                                :xs="24" 
                                :md="12"
                            >
                                <el-form-item label="关键业务：" prop="sjGjyw">
                                    <el-input v-model="Forms.sjGjyw" maxlength="30" show-word-limit placeholder="不超过30个字符"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col 
                                :xs="24" 
                                :md="12"
                            >
                                <el-form-item label="竞品分析：" prop="sjJpfx">
                                    <el-input v-model="Forms.sjJpfx" maxlength="30" show-word-limit placeholder="不超过30个字符"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col 
                                :xs="24" 
                                :md="12"
                            >
                                <el-form-item label="团队能力：" prop="sjTdnl">
                                    <el-input v-model="Forms.sjTdnl" maxlength="30" show-word-limit placeholder="不超过30个字符"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col 
                                :xs="24" 
                                :md="24"
                            >
                                <el-form-item label="项目立意：" prop="purport">
                                    <el-input type="textarea" v-model="Forms.purport" maxlength="200" show-word-limit rows="5" placeholder="不超过200个字符"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form>
                    </el-row>
                    <span slot="footer" class="dialog-footer">
                        <el-button v-show="active==0" @click="dialogVisible = false">取 消</el-button>
                        <el-button v-show="active==0" type="primary" @click="next('ruleForm')">保 存</el-button>
                        <el-button v-show="active==1" @click="dialogVisible = false">取 消</el-button>
                        <el-button v-show="active==1" type="primary" @click="nexts('Forms')">保 存</el-button>
                    </span>
            </el-dialog>
        </div>
        <!-- 编辑弹窗 -->
        <div class="editor_Popup">
            <el-dialog
                :title="coverName"
                :visible.sync="dialogVisibleForm"
                :show-close="false"
                width="60%"
                :before-close="handleClose">
                    <i class="el-icon-close closei" @click="closei('Form')"></i>
                    <el-row :gutter="40">
                        <el-form ref="Form" :model="Form" :rules="rules">
                            <el-col 
                                :xs="24" 
                                :md="8"
                                v-for="(item,index) in Form.mustInput" :key="index"
                            >
                                <el-form-item :label="item.name" :prop="`mustInput.${index}.content`"
                                :rules="[{ required: true, message: '请输入'+item.name+'', trigger: 'blur' }]">
                                    <el-input v-model="item.content" :placeholder="'请输入'+item.name+''"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col 
                                :xs="items.flag!=4?24:12" 
                                :md="items.flag!=4?24:12"
                                v-for="(items,indexs) in Form.subProject" :key="'info2-' + indexs"
                                :class="items.flag==4?'img_form':''"
                            >
                                <el-form-item :label="items.name" :prop="items.flag==1 || items.flag==2?`subProject.${indexs}.content`:`subProject.${indexs}.image`"
                                :rules="[{ required: true, message: items.flag==1 || items.flag==2?'请输入'+items.name+'':'请上传'+items.name+'', trigger: 'blur'}]">
                                    <el-input 
                                        :type="items.flag==1?'text':'textarea'" 
                                        v-model="items.content" 
                                        rows="4" 
                                        :placeholder="'请输入'+items.name+''"
                                        v-if="items.flag==1 || items.flag==2"
                                    ></el-input>
                                    <el-upload
                                        v-else
                                        :action="uploadimage"
                                        list-type="picture-card"
                                        :limit="1"
                                        :file-list="fileList"
                                        :on-remove="(file, fileList) => handleRemove(file,fileList,items)"
                                        :on-preview="handlePictureCardPreview"
                                        :on-success="(response, file, fileList) => headPicHandleSuccess(response,file,fileList,items)"
                                        :class="{hide:items.hideUpload}">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <!-- <el-upload
                                        v-else
                                        class="avatar-uploader"
                                        :action="uploadimage"
                                        :show-file-list="false"
                                        :on-success="(response, file, fileList) => headPicHandleSuccess(response,file,fileList,items)"
                                    >
                                        <img v-if="items.image" :src="items.image" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload> -->
                                </el-form-item>
                                
                                    <!-- 大图查看已上传的图片 -->
                                    <el-dialog width="600px" :visible.sync="dialogVisibleimg" append-to-body>
                                        <img width="100%" :src="dialogImageUrl" alt="">
                                    </el-dialog>
                            </el-col>
                        </el-form>
                    </el-row>
                    <span slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="saveBox('Form')">保 存</el-button>
                    </span>
            </el-dialog>
        </div>
        <!-- 资源库抽屉 -->
        <div class="drawerBox">
            <el-drawer
                size="450px"
                title="资料库"
                :visible.sync="drawer"
                :with-header="true">
                <div class="mu_list">
                    <ul>
                        <li v-for="(item,index) in drawList" :key="index" v-show="drawList.length!=0">
                            <div class="bm-drake-box">
                                <div class="mu-item">
                                    <div class="mu-item-left">
                                        <div class="mu-avatar">
                                            <div class="mu-avatar-inner">
                                                <img src="./../../assets/img/ppt.svg" alt="">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mu-item_item clearfix">
                                        <div class="item_left fl">
                                            <span>{{item.name}}</span>
                                        </div>
                                        <div class="item_right fr">
                                            <i class="iconfont icon-xiazai" @click="download(item)"></i>
                                        </div>
                                    </div>
                                    <hr class="mu-divider">
                                </div>
                            </div>
                        </li>
                        <div class="default-wrapper" v-show="drawList.length==0">
                            <img src="./../../assets/img/bookmark.svg" alt="">
                            <p><span>暂无数据</span></p>
                        </div>
                    </ul>
                </div>
            </el-drawer>
        </div>
        <div class="Radarmap">
            <el-dialog
                :visible.sync="dialogRadarmap"
                :show-close="false"
                title="项目立意分析"
                width="950px"
                :before-close="handleClose"
            >
                <i class="el-icon-close closeis" @click="closeis()"></i>
                <ul class="Radarmap_div clearfix">
                    <li>
                        <p class="Radarmap_name">立意分析雷达图</p>
                        <div id="leiDaTu" class="echart" ref="chart">
                            
                        </div>
                    </li>
                    <li>
                        <p class="Radarmap_name">现阶段竞争力</p>
                        <div class="echart" id="jingZhli">

                        </div>
                    </li>
                    <li>
                        <p class="Radarmap_name">生态位</p>
                        <div class="echart_img">
                            <img :src="niche" alt="">
                        </div>
                    </li>
                    <li>
                        <p class="Radarmap_name">现阶段支撑力</p>
                        <div class="echart" id="zhiChLi">

                        </div>
                    </li>
                </ul>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="dialogRadarmap = false">关 闭</el-button>
                </span>
            </el-dialog>
        </div>
        <!-- 打印弹窗 -->
        <div class="print_popup">
            <el-dialog
                :visible.sync="dialogPrint"
                :show-close="false"
                width="85%"
                :before-close="handleClose"
            >
                <i class="el-icon-close closeis" @click="closeis()"></i>
                <div class="tab_boxs">
                    <div class="tab_boxs_div">
                        <el-button type="primary" @click="printTemplate()">导出为PDF打印</el-button>
                    </div>
                    <span>
                        打印预览（A3横版）
                        <span>
                            提示：布局为横向、纸张大小为A3、边距为无
                        </span>
                    </span>
                </div>
                <div class="print_div" >
                    <div id="print">
                    <div class="print_main_div"  :class="printm.type==1?'creative':'creatives'">
                        <!-- 学校名称 -->
                        <p class="print_name">{{printm.base.title}}</p>
                        <!-- 项目编号 -->
                        <p class="Itemno">
                            No.{{printm.base.code}}
                        </p>
                        <!-- 项目名称 -->
                        <p class="print_name1">{{printm.base.name}}</p>
                        <!-- 头像1 -->
                        <div v-for="(item,index) in account.slice(0,2)" :key="index">
                            <img :src="item.avatar" alt="" :class="index==0?'image2':index==1?'image1':index==2?'image3':''">
                            <span :class="index==0?'image_span2':index==1?'image_span1':index==2?'image_span3':''">{{item.name}}</span>
                        </div>
                        <!-- 项目类型 -->
                        <p :class="printm.type==1?'p_types':'p_type'">{{printm.base.type}}</p>
                        <!-- 联系电话 -->
                        <p :class="printm.type==1?'p_phones':'p_phone'">{{printm.base.mobile}}</p>
                        <!-- 项目立意 -->
                        <p class="conception">
                            {{printm.xmly}}
                        </p>
                        <!-- 项目logo -->
                        <p class="p_logo">
                            <img :src="printm.logo" alt="">
                        </p>
                        <!-- 项目介绍 -->
                        <p class="introduction">
                            {{printm.xmjs}}
                        </p>
                        <!-- 成立时间 -->
                        <p class="Settime" v-show="printm.type==2">
                            {{printm.qyjj_clsj}}
                        </p>
                        <!-- 企业名称 -->
                        <p class="Anname" v-show="printm.type==2">
                            {{printm.qyjj_qymc}}
                        </p>
                        <!-- 公司理念 -->
                        <p class="philosophy" v-show="printm.type==2">
                            {{printm.qyjj_gsln}}
                        </p>
                        <!-- 核心优势 -->
                        <p class="strengths">
                            {{printm.hxys}}
                        </p>
                        <!-- 项目特点 -->
                        <p class="character">
                            {{printm.jjfa_xmtd}}
                        </p>
                        <!-- 方案形态 -->
                        <p class="Planform">
                            {{printm.jjfa_faxt}}
                        </p>
                        <!-- 收入 -->
                        <p class="income">
                            {{printm.cwrz_sr}}
                        </p>
                        <!-- 出让股份 -->
                        <p class="shares">
                            {{printm.cwrz_crgf}}
                        </p>
                        <!-- 融资 -->
                        <p class="financing">
                            {{printm.cwrz_rz}}
                        </p>
                        <!-- 涉及行业 -->
                        <p class="industry">
                            {{printm.scfx_sjhy}}
                        </p>
                        <!-- 目标客户 -->
                        <p class="customer"> 
                            {{printm.scfx_mbkh}}
                        </p>    
                        <!-- 市场价值 -->
                        <p class="market">
                            {{printm.scfx_scjz}}
                        </p>
                        <!-- 销售规划 -->
                        <p class="Salesplan">
                            {{printm.fzgh_xsgh}}
                        </p>
                        <!-- 产品规划 -->
                        <p class="Productplan">
                            {{printm.fzgh_cpgh}}
                        </p>
                        <!-- 竞争分析 -->
                        <p class="analysis">
                            {{printm.jzfx}}
                        </p>
                        <!-- 报告时间 -->
                        <p class="Reporttime">
                            {{printm.time}}
                        </p>
                    </div>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
// import '@/utils/jQuery.print.js'
import '@/utils/jquery-1.4.4.min.js'
import '@/utils/jquery-migrate-1.2.1.min.js'
import '@/utils/jquery.jqprint-0.3.js'
const echarts = require('echarts');
export default {
    data () {
        return {
            drawer: false,
            drawList:[],
            active:0,
            fileList:[],
            ruleForm:{
                resource:1
            },
            Form:{
                mustInput:[],
                subProject:[],
            },
            Forms:{},
            rules:{
                name:{ required: true, message: '项目名称不能为空', trigger:'blur'},
                lyXq:{ required: true, message: '内容不能为空', trigger:'blur'},
                lyMb:{ required: true, message: '内容不能为空', trigger:'blur'},
                lyCx:{ required: true, message: '内容不能为空', trigger:'blur'},
                lySp:{ required: true, message: '内容不能为空', trigger:'blur'},
                lyKx:{ required: true, message: '内容不能为空', trigger:'blur'},
                lyXy:{ required: true, message: '内容不能为空', trigger:'blur'},
                sjMbsc:{ required: true, message: '内容不能为空', trigger:'blur'},
                sjGjyw:{ required: true, message: '内容不能为空', trigger:'blur'},
                sjJpfx:{ required: true, message: '内容不能为空', trigger:'blur'},
                sjTdnl:{ required: true, message: '内容不能为空', trigger:'blur'},
                purport:{ required: true, message: '内容不能为空', trigger:'blur'},
                
            },
            dialogVisible:false,
            dialogVisibleForm:false,
            dialogPrint:false,
            dialogRadarmap:false,
            show:false,
            pro_names:'',
            proList:[],
            login_acc:false,
            defaultProps: {
                children: 'children',
                label: 'label'
            },  
            typeList:[],
            treedata:[],
            cataChecked:[],
            systemNodeFlag:true,
            coverName:'',
            choiceId:[],
            uploadimage:this.$store.state.uploadimage,
            printm:{
                time:'',
                base:{},
            },
            account:[
                {},{},{},
            ],
            leidList:[],
            leidList1:[],
            leidList2:[],
            niche:'',
            dialogVisibleimg:false,
            dialogImageUrl:'',
        }
    },
    directives: {
        close: {
            inserted(el, binding, vnode) {
                window.onclick = function(e) {
                    if (!el.contains(e.target)) {
                        vnode.context.show = false;
                    }
                };
            }
        }
    },
    methods: {
        getapp(){
            this.axios.projectdetail({
                token:this.$store.state.token,
                id:this.$store.state.pro_id
            }).then(res=>{
                if(res.data.code==0){
                    var array = []
                    res.data.data.project.map(item=>{
                        if(item.hasSelect==1){
                            array.push(item)
                        }
                    })
                    this.typeList = array
                    this.treedata = res.data.data.cata
                    this.cataChecked = res.data.data.cataChecked
                    this.$refs.trees.setCheckedKeys(this.cataChecked)
                    this.systemNodeFlag = false
                }
            }).catch(err=>{

            })
        },
        getapps(){
            this.axios.projectmy({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    if(this.$route.query.istrue==1){
                        var array = res.data.data.create
                    }else{
                        var array = res.data.data.join
                    }
                    array.forEach(item=>{
                        if(item.id == this.$store.state.pro_id){
                            this.pro_names = item.name
                        }
                    })
                    this.proList = array
                }else{
                    this.$message.info(res.data.message)
                }
            }).catch(err=>{

            })
        },
        choDiv(val){
            this.$store.dispatch("pro_id",val.id)
            this.pro_names = val.name
            this.systemNodeFlag = true
            this.$nextTick(function () {
                this.getapp()
            });
        },
        drawers(){
            this.axios.coursedoc({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    this.drawList = res.data.data
                    this.drawer = true
                }
            }).catch(err=>{

            })
        },
        // 选中树节点
        checkNode(data, node){
            var arr = node.checkedKeys
            var array = node.halfCheckedKeys
            arr = arr.concat(array)
            var arrays = []
            arr.map(item=>{
                arrays.push(Object.assign({id:item}))
            })
            this.axios.projectchecked({
                token:this.$store.state.token,
                id:this.$store.state.pro_id,
                itemIds:arrays
            }).then(res=>{
                if(res.data.code==0){
                    this.systemNodeFlag = true
                    this.getapp()
                    var ass = []
                    ass.push(data.id)
                    this.choiceId=ass
                }
            }).catch(err=>{

            })
        },
        // 保存
        saveBox(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.axios.projectccatagx({
                        token:this.$store.state.token,
                        id:this.$store.state.pro_id,
                        catas:this.Form.subProject,
                        mustInput:this.Form.mustInput,
                        pId:this.Form.pId
                    }).then(res=>{
                        if(res.data.code==0){
                            this.$message.success('保存成功')
                            this.dialogVisibleForm = false
                            this.getapp()
                        }else{
                            this.$message.info(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }
            })
        },
        download(item){
            window.open(item.url, "_blank");
        },  
        // 获取打印数据
        printBox(){
            this.axios.projectcprint({
                token:this.$store.state.token,
                id:this.$store.state.pro_id
            }).then(res=>{
                if(res.data.code==0){
                    this.printm = res.data.data
                    this.account = res.data.data.base.avatar
                    var date = new Date()
                    var y = date.getFullYear();
                    var m = date.getMonth() + 1;
                    m = m < 10 ? '0' + m : m;
                    var d = date.getDate();
                    d = d < 10 ? ('0' + d) : d;
                    this.printm.time  = y + '/' + m + '/' + d;
                    console.log(this.printm.time)
                    this.dialogPrint =true 
                }
            }).catch(err=>{

            })
            
            
        },
        exit(){
            this.$router.push('/login')
            localStorage.clear()
        },
        // 上传图片
        headPicHandleSuccess(res,file,fileList,val){
            val.hideUpload = fileList.length >= 1;
            val.image = res.data
        },
        //删除图片
        handleRemove(file,fileList,val) {
            setTimeout(() => {
                val.hideUpload = fileList.length >= 1;
            }, 1000);
        },
        //查看大图
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisibleimg = true;
        },
        //修改项目详情
        updateBtn(item){
            this.axios.projectccatadt({
                token:this.$store.state.token,
                id:this.$store.state.pro_id,
                cId:item.contentId
            }).then(res=>{
                if(res.data.code==0){
                    this.coverName = item.name
                    this.mustInput = res.data.data.mustInput
                    this.pId = res.data.data.pId
                    var arrays = res.data.data.subProject
                    const arr = []
                    arrays.map((item,i)=>{
                        arr.push(Object.assign(item,{hideUpload:false}))
                    })
                    this.Form.mustInput = this.mustInput
                    this.Form.pId = this.pId
                    this.Form.subProject = arr
                    this.dialogVisibleForm = true
                }
            }).catch(err=>{

            })
        }, 
        // 雷达图
        loadLeida() {
            // 雷达图
            let option = {
                tooltip: {},
	            legend: {
                },
                radar: {
                    // shape: 'circle',
                    center: ['50%', '50%'],
                    radius: 100,
                    startAngle: 90,
                    indicator: [
                        { name: '存在描述', max: 10},
                        { name: '商业描述', max: 10},
                        { name: '场景描述', max: 10},
                        { name: '能力描述', max: 10},
                        { name: '差异描述', max: 10},
                        { name: '结果描述', max: 10}
                    ],
                    splitArea: {
                        show: true,
                        areaStyle: {
                            // color: 'rgba(255,0,0,0)', // 图表背景的颜色
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            width: 1,
                            // color: 'rgba(131,141,158,.1)', // 设置网格的颜色
                        },
                    },
                },
                series: [{
                    name: '立意分析雷达图',
                    type: 'radar',
                    itemStyle: {     //此属性的颜色和下面areaStyle属性的颜色都设置成相同色即可实现
                        color: '#5840D4',
                        borderColor: '#5840D4',
                    },
                    areaStyle: {
                        color: '#5840D4',
                    },
                    data: [
                        {
                            value: this.leidList,
                        },
                    ]
                }],
            }
            //现阶段竞争力
            let option1 = {
                series: [{
                    type: 'gauge',
                    progress: {
                        show: true,
                        width:10,
                    },
                    axisTick: {
                        show:false,
                        splitNumber: 5  ,
                        length: 4,
                    },
                    axisLine: {
                        lineStyle: {
                            width: 10
                        }
                    },
                    splitLine: {
                        length: 10,
                        lineStyle: {
                            width: 2,
                            color: '#999'
                        }
                    },
                    axisLabel: {
                        distance: 10,
                        color: '#999',
                        fontSize: 12
                    },
                    anchor: {
                        show: true,
                        showAbove: true,
                        size: 15,
                        itemStyle: {
                            borderWidth: 10
                        }
                    },
                    title: {
                        show: false
                    },
                    detail: {
                        valueAnimation: true,
                        fontSize: 40,
                        offsetCenter: [0, '70%']
                    },
                    data: this.leidList1
                }]
            }
            //现阶段支撑力
            let option2 = {
                series: [{
                    type: 'gauge',
                    startAngle: 180,
                    endAngle: 0,
                    min: 0,
                    max: 100,
                    splitNumber: 5,
                    itemStyle: {
                        color: '#58D9F9',
                        shadowColor: 'rgba(0,138,255,0.45)',
                        shadowBlur: 10,
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    },
                    progress: {
                        show: true,
                        roundCap: true,
                        width: 10
                    },
                    pointer: {
                        length: '70%',
                        width: 10,
                        offsetCenter: [0, '5%']
                    },
                    axisLine: {
                        roundCap: true,
                        lineStyle: {
                            width: 8
                        }
                    },
                    axisTick: {
                        show:false,
                        splitNumber: 5,
                        length:4,
                        lineStyle: {
                            width: 1,
                            color: '#999'
                        }
                    },
                    splitLine: {
                        length: 12,
                        lineStyle: {
                            width: 3,
                            color: '#999'
                        }
                    },
                    axisLabel: {
                        distance: 10,
                        color: '#999',
                        fontSize: 12
                    },
                    title: {
                        show: false
                    },
                    detail: {
                        offsetCenter: [0, '50%'],
                        valueAnimation: true,
                        rich: {
                            value: {
                                fontSize: 20,
                                fontWeight: 'bolder',
                                color: '#777'
                            },
                        }
                    },
                    data: this.leidList2
                }]
            }
            this.$nextTick(function () {    
                    // 雷达图
                    var bar_dv = document.getElementById('leiDaTu')
                    let myChart = echarts.init(bar_dv)
                    myChart.setOption(option)
                    //现阶段竞争力
                    var bar_dv1 = document.getElementById('jingZhli')
                    let myChart1 = echarts.init(bar_dv1)
                    myChart1.setOption(option1)
                    //现阶段支撑力
                    var bar_dv2 = document.getElementById('zhiChLi')
                    let myChart2 = echarts.init(bar_dv2)
                    myChart2.setOption(option2)
            });
                
        },
        // 查看项目基本信息
        Newadd(){
            this.active = 0
            this.axios.projectbase({
                token:this.$store.state.token,
                id:this.$store.state.pro_id,
            }).then(res=>{
                if(res.data.code==0){
                    this.dialogVisible = true
                    this.ruleForm = res.data.data.base
                }else{
                    this.$message.info(res.data.message)
                }
            }).catch(err=>{

            })
            
        },
        istrues(val){
            this.active = val
            if(val==0){
                this.axios.projectbase({
                    token:this.$store.state.token,
                    id:this.$store.state.pro_id,
                }).then(res=>{
                    if(res.data.code==0){
                        this.ruleForm = res.data.data.base
                    }else{
                        this.$message.info(res.data.message)
                    }
                }).catch(err=>{

                })
            }else{
                this.axios.projectbase({
                    token:this.$store.state.token,
                    id:this.$store.state.pro_id,
                }).then(res=>{
                    if(res.data.code==0){
                        this.Forms = res.data.data.base
                    }else{
                        this.$message.info(res.data.message)
                    }
                }).catch(err=>{

                })
            }
        },
        //保存
        next(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.token = this.$store.state.token
                    this.axios.projectbaseUpdate(
                        this.ruleForm
                    ).then(res=>{
                        if(res.data.code==0){
                            this.dialogVisible = false
                        }else{
                            this.$message.info(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }
            })
        },
        nexts(Forms){
            this.$refs[Forms].validate((valid) => {
                if (valid) {
                    this.Forms.token = this.$store.state.token
                    this.axios.projectbaseUpdate(
                        this.Forms
                    ).then(res=>{
                        if(res.data.code==0){
                            this.dialogVisible = false
                        }else{
                            this.$message.info(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }
            })
        },
        // 雷达图弹窗
        mapBotn(){
            this.axios.projectcradar({
                token:this.$store.state.token,
                id:this.$store.state.pro_id
            }).then(res=>{
                if(res.data.code==0){
                    this.leidList = res.data.data.leida
                    var array = []
                    array.push(Object.assign({value:res.data.data.jzfx * 10}))
                    this.leidList1 = array
                    var arr = []
                    arr.push(Object.assign({value:res.data.data.zcl * 10}))
                    this.leidList2 = arr
                    this.niche = res.data.data.stw
                    this.dialogRadarmap = true
                    this.loadLeida()  //雷达图
                }else{

                }
            }).catch(err=>{

            })
            
        },
        navBox(){
            if(this.show==true){
                this.show=false
            }else{
                this.show=true
            }
            
        },
        mouseover(){
            this.login_acc = true
        },
        mouseout(){
            this.login_acc = false
        },
        //返回上一层
        back(){
            this.$router.push({name:'Projectcreation'})
        },
        handleClose(done){

        },
        // 关闭弹窗
        closei(formName){
            this.dialogVisibleForm = false
            this.$refs[formName].resetFields();
        },
        closeis(){
            this.dialogPrint = false
            this.dialogRadarmap = false
        },
        count(){
            this.$router.push({name:'StudentSetting'})
        },
        // 打印
        printTemplate() {
            $('#print').jqprint({

            })
		},
    },
    mounted() {
        this.getapp()
        this.getapps()
    },
}
</script>
<style scoped>
@import './../../assets/css/student/Createaproject.css';
</style>
<style>
.popoverDiv{
    height: 400px;
    overflow-y: auto;
}
.popoverDiv::-webkit-scrollbar {
    width: 6px;
}
.popoverDiv::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border: 1px solid #fff;
    border-radius: 10px;
}
.p_center_left .el-tree-node__content{
    height: 30px;
}
.p_center_left .el-tree-node__children .el-tree-node__content{
    padding-left: 12px!important;
}
.right_top .el-button{
    font-size: 14px;
    padding: 12px 12px;
}
.right_top .el-button+.el-button{
    margin-left: 5px;
}
.joinclass_pop .el-dialog{
    padding: 25px;
}
.joinclass_pop .el-form-item{
    margin-bottom: 15px;
}
.joinclass_pop .el-dialog__header{
    padding: 0;
    /* padding-bottom: 15px; */
    /* border-bottom: 2px solid #e5e5e5; */
}
.joinclass_pop .el-dialog__body{
    padding: 0px 0px 20px;
}
.joinclass_pop .el-form-item__label{
    line-height: 30px;
    font-size: 14px;
    font-weight: bold;
    color: #797979;
}
.joinclass_pop .el-radio-group{
    width: 100%;
}
.joinclass_pop .el-dialog__footer{
    text-align: center;
    padding:0
}
.joinclass_pop .el-row{
    padding: 30px 20px 0;
}


/* 打印弹窗 */
.print_popup .el-dialog__header{
    padding: 0;
}
.print_popup .el-dialog{
    margin-top: 3vh!important;
    max-height: 90vh;
}
.Radarmap .el-dialog{
    margin-top: 3vh!important;
    padding: 25px;
}
.Radarmap .el-dialog__header {
    padding: 0;
    padding-bottom: 15px;
    border-bottom: 2px solid #e5e5e5;
}
.Radarmap .el-dialog__body{
    padding: 0;
}
.Radarmap .el-dialog__footer{
    text-align: center;
    padding:0;
    padding-top: 15px;
}

/* 编辑弹窗 */
.editor_Popup .el-dialog{
    margin-top: 10vh!important;
}
.editor_Popup .el-dialog__header{
    background: #409eff;
    padding: 0 15px;
    height: 40px;
    line-height: 40px;
}
.editor_Popup .el-dialog__body{
    max-height: 60vh;
    overflow-y: auto;
}
.editor_Popup .el-form-item__label{
    line-height: 25px;
    font-size: 14px;
    font-weight: bold;
    color: #797979;
}
.editor_Popup .el-form-item__content{
    line-height: 1;
}
.editor_Popup .el-dialog__title{
    color: #fff;
    font-size: 16px;
    line-height: 40px;
}
.editor_Popup .el-dialog__footer{
    text-align: center;
}
.editor_Popup .el-textarea__inner{
    resize: none;
}
/* 资源库抽屉 */
.drawerBox .el-drawer__header{
    padding: 0 8px;
    height: 44px;
    line-height: 44px;
    background: #f1f6fe;
    color: #0e1726;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
}
.editor_Popup .avatar-uploader{
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 100%;
    line-height: 1;
}
.editor_Popup .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.editor_Popup .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.editor_Popup .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 110px;
    height: 110px;
    line-height: 110px;
    text-align: center;
}
.editor_Popup .avatar {
    width: 110px;
    height: 110px;
    display: block;
}
.editor_Popup .img_form .el-form-item__label{
    width: 120px;
}
.editor_Popup .img_form .avatar-uploader{
    display: block;
}
.hide{
    height: 148px;
    overflow: hidden;
}
.hide .el-upload--picture-card {
    display: none;
}
.hide .el-upload-list--picture-card .el-upload-list__item{
    margin-bottom: -2px;
}
</style>
